export const mobileValidation = (num: string) => { return /^[6789]\d{9}$/.test(num); }
export const emailValidation = (email: string) => { return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email); }
export const pinValidation = (pin: string) => { return /^\d{6}$/.test(pin) };
export const aadharValidation = (aadhar: string) => { return /^\d{12}$/.test(aadhar) };
export const validateExperience = (experience: string): boolean => {
    if (!/^\d+(\.\d)?$/.test(experience)) return false;
    const experienceNumber = parseFloat(experience);
    if (experienceNumber >= 50) return false;
    return true;
};
export const nameValidation = (name: string) => { /^[a-zA-Z ]+$/.test(name); }
export const numberValidation = (num: string) => { /^\d+$/.test(num) };
export const panValidation = (pan: string) => { return /^[a-zA-Z]{5}\d{4}[a-zA-Z]$/.test(pan) };
export const ifscValidation = (ifsc: string) => { return /^\d{11}$/.test(ifsc); };
export const gstValidation = (gst: string) => { return /^\d{2}[a-zA-Z]{5}\d{4}[a-zA-Z]\d[a-zA-Z]\d$/.test(gst) };
export const referralValidation = (code: string) => { return /^[a-zA-Z0-9]{6,32}$/.test(code); };
