import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

export const updateReferralCode = createAsyncThunk("referral/updateReferralCode", async (code: string) => {
  try {
    const response = await axios.get(baseUrl + `v1/user/referral/change?referral=${code}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const barCodeGenrator = createAsyncThunk("referral/barCodeGenrator", async (data: string) => {
    const response = await axios.get(baseUrl + `v1/user/getqrCode?link=${data}`);
    return response.data;
});
