import React, { useEffect, useState } from "react";
import SubcategoryTab from "../../../component/common/SubcategoryTab";
import view from "../../../assets/icons/gallery/view.svg";
import download from "../../../assets/icons/gallery/download.svg";
import Typography from "../../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { downloadTemplateThunk, getGalleryContentThunk } from "../../../store/thunk/Gallery.thunk";
import ViewImg from "./Dialogs/ViewImg";
import Spinner from "../../../component/common/Spinner";
import { getCurrentUser } from "../../../helpers/currentUser";
import { PERMISSIONS } from "../../../helpers/constants/constants";
import SendToPartner from "./Dialogs/SendToPartner";
import { barCodeGenrator } from "../../../store/thunk/Referral.thunk";
import { scannerURL } from "../../../helpers/constants/defaultUrls";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import downloadIcon from "../../../assets/icons/gallery/downloadIcon.svg";

const BrandingCreatives = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showImg, setShowImg] = useState(false);
  const [selectedImage, setSelectedImg] = useState("");
  const [isLoading, setLoading] = useState("");
  const [sendToPartnerOpen, setsendToPartnerOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [showAction, setShowAction] = useState(-1);
  const [modalSrc, setModalSrc] = useState("");

  const dispatch = useAppDispatch();

  const standeeData = useAppSelector((state) => state.rootReducer.gallery.galleryStandee.data);
  const postersData = useAppSelector((state) => state.rootReducer.gallery.galleryPosters.data);
  const bannerData = useAppSelector((state) => state.rootReducer.gallery.galleryBanner.data);
  const businessCardData = useAppSelector((state) => state.rootReducer.gallery.galleryBusinessCards.data);
  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);
  const partnerLogoUrl: string = useAppSelector((state) => state.rootReducer.common.partnerLogo);

  useEffect(() => {
    if (activeTab == 1 && !standeeData) dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Standee" }));
    if (activeTab == 2 && !postersData) dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Posters" }));
    if (activeTab == 3 && !bannerData) dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Banner" }));
    if (activeTab == 4 && !businessCardData) dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Business Cards" }));
  }, [activeTab]);

  const handleDownload = (id: any) => {
    setLoading(id);
    dispatch(downloadTemplateThunk({ id }))
      .unwrap()
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "image/png" });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "template.png"; // Specify the filename
          document.body.appendChild(a);
          a.click();
          a.remove();

          // Optional: revoke the URL object to free up memory
          window.URL.revokeObjectURL(url);
          setLoading("");
        }
      });
  };
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch(barCodeGenrator(scannerURL + userData?._id))
      .unwrap()
      .then((data) => {
        if (data?.success) setReferralCode(data?.data);
      });
  }, []);

  const tabs = ["Standee", "Posters", "Banners", "Business Cards"];

  return (
    <>
      <div className="w-full flex h-full justify-center">
        <SubcategoryTab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      </div>
      {activeTab == 1 && (
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {standeeData &&
            standeeData.map((obj: any, i: any) => {
              if (isLoading == obj._id) {
                return (
                  <div key={"download" + i} className="h-full bg-gray-300 relative flex justify-center items-center">
                    <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto" />
                    <div className="absolute inset-0 flex justify-center items-center">
                      <Spinner className="text-3xl" />
                    </div>
                  </div>
                );
              }
              return (
                <div key={i} className={`w-full border-2 shadow-lg relative ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
                  <img src={obj.mediaUrl} className="w-full " />

                  {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? <img id="referralCode" src={referralCode} className="absolute top-[34.5%] right-[38%] w-[23%] h-[10%] rounded" /> : ""}

                  {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[1%] left-[4%] w-[21%] h-[5%]" />}
                  <div className="absolute bottom-[2%] w-full py-2   pl-8">
                    <p className="font-bold text-white text-center  text-2xl">{userData?.name ? userData?.name.toUpperCase() : ""}</p>
                    <div className="flex gap-2 items-center justify-center  ">
                      <FaPhoneAlt className="text-white text-2xl" />
                      <p className="font-bold text-white text-2xl">{userData?.mobile}</p>
                    </div>
                  </div>

                  <div className={`absolute transition-all w-full h-full top-0  brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                    <div className="flex absolute items-center justify-center h-full w-full">
                      <div className="flex gap-4  justify-center items-start">
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img
                            src={view}
                            onClick={() => {
                              setShowImg(true);
                              setSelectedImg(obj.mediaUrl);
                              setModalSrc("standee");
                            }}
                          />
                          <Typography className="text-white">View</Typography>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img src={download} className="filter " onClick={() => handleDownload(obj._id)} />
                          <Typography className="text-white">Download</Typography>
                        </div>
                        {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
                          <div className="flex w-1/3  flex-col justify-center items-center gap-1 cursor-pointer ">
                            <img
                              src={downloadIcon}
                              onClick={() => {
                                setSelectedImg(obj?.mediaUrl);
                                setsendToPartnerOpen(true);
                              }}
                              className="w-6 h-6  md:h-10 md:w-10"
                            />
                            <p className="text-white text-sm lg:text-base text-center">Download For Partners</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {activeTab == 2 && (
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {postersData &&
            postersData.map((obj: any, i: any) => {
              if (isLoading == obj._id) {
                return (
                  <div key={"download" + i} className="h-full bg-gray-300 relative flex justify-center items-center">
                    <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto" />
                    <div className="absolute inset-0 flex justify-center items-center">
                      <Spinner className="text-3xl" />
                    </div>
                  </div>
                );
              }
              return (
                <div key={i} className={`w-full border-2 shadow-lg relative ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
                  <img src={obj.mediaUrl} className="w-full " />
                  <div className="absolute bottom-[3.3%] w-full py-2   pl-8">
                    <div className="flex gap-2 items-center   ">
                      <FaPhoneAlt className="text-white" />
                      <p className="font-bold text-white">{userData?.mobile}</p>
                    </div>
                    <div className="flex gap-2 items-center   ">
                      <IoMdMail className="text-white" />
                      <p className="font-bold text-white">{userData?.email}</p>
                    </div>
                  </div>
                  {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? <img id="referralCode" src={referralCode} className="absolute bottom-[2.9%] right-[4.2%] w-[15.7%] h-[10.7%] rounded" /> : ""}

                  <div className={`absolute transition-all w-full h-full top-0  brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                    <div className="flex gap-4 absolute items-center justify-center h-full w-full">
                      <div className="flex gap-4  justify-center items-start">
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img
                            src={view}
                            onClick={() => {
                              setShowImg(true);
                              setSelectedImg(obj.mediaUrl);
                              setModalSrc("poster");
                            }}
                          />
                          <Typography className="text-white">View</Typography>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img src={download} className="filter " onClick={() => handleDownload(obj._id)} />
                          <Typography className="text-white">Download</Typography>
                        </div>
                        {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
                          <div className="flex w-1/3  flex-col justify-center items-center gap-1 cursor-pointer ">
                            <img
                              src={downloadIcon}
                              onClick={() => {
                                setSelectedImg(obj?.mediaUrl);
                                setsendToPartnerOpen(true);
                              }}
                              className="w-6 h-6  md:h-10 md:w-10"
                            />
                            <p className="text-white text-sm lg:text-base text-center">Download For Partners</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {activeTab == 3 && (
        <div className=" grid grid-cols-1 md:grid-cols-2  gap-8 mt-8">
          {bannerData &&
            bannerData.map((obj: any, i: any) => {
              if (isLoading == obj._id) {
                return (
                  <div key={"download" + i} className="h-full rounded-xl overflow-hidden bg-gray-300 relative flex justify-center items-center">
                    <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto" />
                    <div className="absolute inset-0 flex justify-center items-center">
                      <Spinner className="text-3xl" />
                    </div>
                  </div>
                );
              }
              return (
                <div key={i} className={`w-full rounded-xl border-2 overflow-hidden shadow-lg relative ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
                  <img src={obj.mediaUrl} className="w-full " />
                  {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[4%] left-[4%] w-[15%] h-[13%]" />}
                  {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? <img id="referralCode" src={referralCode} className="absolute bottom-[7%] right-[3.5%] w-[9%] h-[18.5%] rounded" /> : ""}
                  <div className="absolute bottom-[1%] left-[3%] w-full py-2 gap-4  flex  ">
                    <div className="flex  gap-2  items-center   ">
                      <FaPhoneAlt className="text-white" />
                      <p className="font-bold text-white">{userData?.mobile}</p>
                    </div>
                    <div className="flex gap-2 items-center   ">
                      <IoMdMail className="text-white" />
                      <p className="font-bold text-white">{userData?.email}</p>
                    </div>
                  </div>
                  <div className={`absolute transition-all w-full h-full top-0  brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                    <div className="flex gap-4 absolute items-center justify-center h-full w-full">
                      <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                        <img
                          src={view}
                          onClick={() => {
                            setShowImg(true);
                            setSelectedImg(obj.mediaUrl);
                            setModalSrc("banner");
                          }}
                        />
                        <Typography className="text-white">View</Typography>
                      </div>
                      <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                        <img src={download} className="filter " onClick={() => handleDownload(obj._id)} />
                        <Typography className="text-white">Download</Typography>
                      </div>
                      {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
                        <div className="flex w-1/3  flex-col justify-center items-center gap-1 cursor-pointer ">
                          <img
                            src={downloadIcon}
                            onClick={() => {
                              setSelectedImg(obj?.mediaUrl);
                              setsendToPartnerOpen(true);
                            }}
                            className="w-6 h-6  md:h-10 md:w-10"
                          />
                          <p className="text-white text-sm lg:text-base text-center">Download For Partners</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {activeTab == 4 && (
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {businessCardData &&
            businessCardData.map((obj: any, i: any) => {
              if (isLoading == obj._id) {
                return (
                  <div key={"download" + i} className="h-full bg-gray-300 relative flex justify-center items-center">
                    <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto" />
                    <div className="absolute inset-0 flex justify-center items-center">
                      <Spinner className="text-3xl" />
                    </div>
                  </div>
                );
              }
              return (
                <div key={i} className={`w-full border-2 shadow-xl relative ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
                  <img src={obj.mediaUrl} className="w-full " />
                  {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? (
                    <>
                      <div className="absolute top-[7%] left-[40%] w-full py-2 gap-4    ">
                        <p className="font-extrabold">{userData?.name}</p>
                        <p className="font-bold pl-[5%] pt-[0.5%]">{userData?.mobile}</p>
                        <p className="font-bold pl-[5%]">{userData?.email}</p>
                      </div>

                      <img id="referralCode" src={referralCode} className="absolute bottom-[18%] right-[9.7%] w-[20%] h-[20%] rounded" />
                    </>
                  ) : (
                    ""
                  )}

                  <div className={`absolute transition-all w-full h-full top-0  brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                    <div className="flex gap-4 absolute items-center justify-center h-full w-full">
                      <div className="flex gap-4  justify-center items-start">
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img
                            src={view}
                            onClick={() => {
                              setShowImg(true);
                              setSelectedImg(obj.mediaUrl);
                              setModalSrc("card");
                            }}
                          />
                          <Typography className="text-white">View</Typography>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img src={download} className="filter " onClick={() => handleDownload(obj._id)} />
                          <Typography className="text-white">Download</Typography>
                        </div>
                        {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
                          <div className="flex w-1/3  flex-col justify-center items-center gap-1 cursor-pointer ">
                            <img
                              src={downloadIcon}
                              onClick={() => {
                                setSelectedImg(obj?.mediaUrl);
                                setsendToPartnerOpen(true);
                              }}
                              className="w-6 h-6  md:h-10 md:w-10"
                            />
                            <p className="text-white text-sm lg:text-base text-center">Download For Partners</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <ViewImg src={modalSrc} setShowImg={setShowImg} showImg={showImg} imgSrc={selectedImage} className={activeTab == 3 ? "w-[80%]" : "w-[38%]"} />
      <SendToPartner addLogoModal={sendToPartnerOpen} setShowAddLogoModal={setsendToPartnerOpen} imgSrc={selectedImage} />
    </>
  );
};

export default BrandingCreatives;
