import React from "react";
import { BsThreeDots } from "react-icons/bs";
import Menu from "../../../component/common/Menu";
import Typography from "../../../component/semantic/Typography";
import { Tooltip } from "react-tooltip";

export const ActionModalHandler = ({ index, actions, data, selectedRow, showMenu, setShowMenu, className }: any) => {
  return (
    <td className="" key={index}>
      <div className={`md:flex w-40 flex-wrap justify-start py-2 h-full gap-x-6 gap-y-2 pl-1 text-center hidden ${className}`}>
        {actions.map((x: any, i: number) => {
          if (x.isAuthorizedRoute) {
            const { actionPlaceholder = "-" } = x;
            let d = 2;
            if (x?.isDisplay && x?.isDisplay(data)) {
              d = 1;
            }
            return d == 1 ? (
              actionPlaceholder
            ) : (
              <>
                <div
                  className="flex justify-center items-center h-full relative"
                  onClick={() => {
                    selectedRow(data);
                    x.onClickFunction();
                  }}
                  data-tooltip-id={`my-tooltip-${i}`}
                  data-tooltip-content={x.tooltip}
                >
                  {x.image}
                  <Tooltip className="z-50" delayShow={1} key={i} id={`my-tooltip-${i}`} place="bottom" />
                </div>
              </>
            );
          }
        })}
      </div>
      <div className="flex md:hidden justify-center ">
        <BsThreeDots className="text-3xl cursor-pointer" onClick={() => setShowMenu(index)} />
        <Menu showMenu={showMenu == index} onClose={() => setShowMenu(-2)} className="w-48 top-[3.5rem] left-10 right-0 z-50">
          <ul>
            {actions.map((x: any, i: number) => {
              if (x.isAuthorizedRoute) {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      selectedRow(data);
                      x.onClickFunction();
                    }}
                    className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme "
                  >
                    {x.image}
                    <Typography>{x.tooltip}</Typography>
                  </li>
                );
              }
            })}
          </ul>
        </Menu>
      </div>
    </td>
  );
};
