import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import phoneIcon from "../../../../assets/icons/gallery/phone.svg";
import { getCurrentUser } from "../../../../helpers/currentUser";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_toolkit";
import { barCodeGenrator } from "../../../../store/thunk/Referral.thunk";
import { referralUrl, scannerURL } from "../../../../helpers/constants/defaultUrls";
import { PERMISSIONS } from "../../../../helpers/constants/constants";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

interface ViewImgProps {
  showImg: boolean;
  setShowImg: any;
  imgSrc: string;
  className?: string;
  fromReferral?: boolean;
  src?: string;
  qrCode?: "lead" | "partner";
}

const ViewImg: FC<ViewImgProps> = ({ showImg, setShowImg, imgSrc, className, fromReferral, src, qrCode }) => {
  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);
  const partnerLogoUrl: string = useAppSelector((state) => state.rootReducer.common.partnerLogo);
  const [referralCode, setReferralCode] = useState("");

  const dispatch = useAppDispatch();
  useEffect(() => {
    let qrUrl;
    if (qrCode === "partner") qrUrl = referralUrl + userData?.referralCode;
    else qrUrl = scannerURL + userData?._id;
    dispatch(barCodeGenrator(qrUrl))
      .unwrap()
      .then((data) => {
        if (data?.success) setReferralCode(data?.data);
      });
  }, []);

  const userInfo = getCurrentUser();
  return (
    <Modal showModal={showImg} className={`${src ? "w-auto" : "w-5/12"} p-12 ${src && "h-auto md:h-[90%]"} mx-10 relative  ${className}`} onClose={() => setShowImg(false)}>
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setShowImg(false)} />
      <div className="relative h-full overflow-hidden">
        <img src={imgSrc} className="w-full h-full object-contain" />
        {src === "standee" && (
          <>
            <img id="referralCode" src={referralCode} className="absolute top-[34.5%] right-[38%] w-[23%] h-[10%] rounded" />
            {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[1%] left-[4%] w-[21%] h-[5%]" />}
            <div className="absolute bottom-[0%] w-full py-2   pl-8">
              <p className="font-bold text-white text-center  text-2xl">{userData?.name ? userData?.name.toUpperCase() : ""}</p>
              <div className="flex gap-2 items-center justify-center  ">
                <FaPhoneAlt className="text-white text-2xl" />
                <p className="font-bold text-white text-2xl">{userData?.mobile}</p>
              </div>
            </div>{" "}
          </>
        )}
        {src === "poster" && (
          <>
            <div className="absolute bottom-[3.3%] w-full py-2   pl-8">
              <div className="flex gap-2 items-center   ">
                <FaPhoneAlt className="text-white" />
                <p className="font-bold text-white">{userData?.mobile}</p>
              </div>
              <div className="flex gap-2 items-center   ">
                <IoMdMail className="text-white" />
                <p className="font-bold text-white">{userData?.email}</p>
              </div>
            </div>
            <img id="referralCode" src={referralCode} className="absolute bottom-[2.9%] right-[4.2%] w-[15.7%] h-[10.7%] rounded" />
          </>
        )}

        {src === "banner" && (
          <>
            {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[4%] left-[4%] w-[15%] h-[13%]" />}
            <img id="referralCode" src={referralCode} className="absolute bottom-[7%] right-[3.5%] w-[9%] h-[18.5%] rounded" />
            <div className="absolute bottom-[1%] left-[3%] w-full py-2 gap-4  flex  ">
              <div className="flex  gap-2  items-center   ">
                <FaPhoneAlt className="text-white" />
                <p className="font-bold text-white">{userData?.mobile}</p>
              </div>
              <div className="flex gap-2 items-center   ">
                <IoMdMail className="text-white" />
                <p className="font-bold text-white">{userData?.email}</p>
              </div>
            </div>
          </>
        )}

        {src === "card" && (
          <>
            <div className="absolute top-[10%] left-[40%] w-full py-2 gap-4    ">
              <p className="font-extrabold md:text-lg lg:text-2xl">{userData?.name}</p>
              <p className="font-bold pl-[5%] pt-[0.5%] md:text-lg lg:text-2xl">{userData?.mobile}</p>
              <p className="font-bold pl-[5%] md:text-lg lg:text-2xl mt-[1.5%]">{userData?.email}</p>
            </div>
            <img id="referralCode" src={referralCode} className="absolute bottom-[18%] right-[9.7%] w-[20%] h-[20%] rounded" />
          </>
        )}
        {!userInfo?.roles.includes(PERMISSIONS.ADMIN) && !src ? (
          <>
            {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[2%] left-[4%] w-[20%] h-[10%]" />}
            {fromReferral ? (
              <>
                <div className="absolute bottom-0 w-full py-6 pl-10">
                  <p className="font-bold text-2xl text-white">{userInfo?.name}</p>
                  <div className="flex gap-2">
                    <img src={phoneIcon} className="w-4" />
                    <p className="font-bold text-2xl text-white">{userInfo?.mobile}</p>
                  </div>
                </div>
                <img id="referralCode" src={referralCode} className="absolute bottom-[2%] right-[4%] w-[18%] h-[18%] rounded" />
              </>
            ) : (
              <div className="absolute bottom-0 w-full py-6 bg-[#00000066] pl-10">
                <p className="font-bold text-2xl text-white">{userInfo?.name}</p>
                <div className="flex gap-2">
                  <img src={phoneIcon} className="w-4" />
                  <p className="font-bold text-2xl text-white">{userInfo?.mobile}</p>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
};

export default ViewImg;
