import React, { useEffect, useState } from "react";
import FormInput from "../../../component/semantic/FormInput";
import Select from "react-select";
import { polyfixSelect } from "../../../helpers/constants/styles";
import { reportDateTypeLead } from "../../../helpers/constants/constants";
import Typography from "../../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { getDropdownThunk } from "../../../store/thunk/Common.thunk";
import { getCurrentUser } from "helpers/currentUser";

interface ReportProps {
  formProps: any;
}

const LeadReport: React.FC<ReportProps> = ({ formProps }) => {
  const [userListArr, setUserListArr] = useState<any>([]);
  const [listStatusArr, setLeadStatusArr] = useState<any>([]);
  const [associateListArr, setAssociateListArr] = useState([]);
  const [advisorListArr, setAdvisorListArr] = useState([]);

  const dispatch = useAppDispatch();
  const currentUser = getCurrentUser();

  const userList = useAppSelector((state) => state.rootReducer.common.userDropdownList);
  const leadStatus = useAppSelector((state) => state.rootReducer.report.leadStatus);

  useEffect(() => {
    if (userList.data) setUserListArr(userList.data);
    if (leadStatus.data) setLeadStatusArr(leadStatus?.data.map((obj: any) => ({ label: obj, value: obj })));
  }, [userList, leadStatus]);

  const handlePartnerChange = (option: any) => {
    if (option._id == currentUser._id) {
      formProps.setFieldValue("grandChildId", undefined);
      formProps.setFieldValue("childId", undefined);
      formProps.setFieldValue("userId", currentUser?._id);
      return;
    }
    formProps.setFieldValue("userId", option._id);

    dispatch(getDropdownThunk({ userType: "Vendor", hierarchicalLevel: 1, parentId: option._id }))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setAssociateListArr(res?.data);
          setAdvisorListArr([]);
          formProps.setFieldValue("childId", undefined);
          formProps.setFieldValue("grandChildId", undefined);
        }
      });
  };

  const handleAssociateChange = (option: any) => {
    if (option._id == currentUser._id) {
      formProps.setFieldValue("grandChildId", undefined);
      formProps.setFieldValue("childId", undefined);
      return;
    }
    formProps.setFieldValue("childId", option._id);
    dispatch(getDropdownThunk({ userType: "Vendor", hierarchicalLevel: 2, parentId: option?._id, grandParentId: formProps.values?.userId }))
      .unwrap()
      .then((res) => {
        if (res?.success) setAdvisorListArr(res?.data);
      });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {/* <FormInput name="reportType" label="Report Type" disabled formProps={formProps} /> */}
      <span>
        <Typography className="font-normal mb-1">Select Date Type</Typography>
        <Select
          options={reportDateTypeLead}
          name="type"
          placeholder="Select Date Type"
          onChange={(option) => {
            formProps.setFieldValue("type", option?.value);
          }}
          styles={polyfixSelect}
          isSearchable={false}
        />
      </span>
      <span>
        <Typography className="font-normal mb-1">Complaint Status</Typography>
        <Select
          options={[{ label: "All", value: "All" }, ...listStatusArr]}
          className="w-full "
          name="status"
          onChange={(option: any) => {
            formProps.setFieldValue("status", option?.value);
          }}
          styles={polyfixSelect}
          placeholder="Select Complaint Status"
        />
      </span>
      <FormInput name="startDate" max="true" type="date" label="Start Date" formProps={formProps} />
      <FormInput name="endDate" min={formProps.values.startDate} type="date" label="End Date" formProps={formProps} />

      <span>
        <Typography className="font-normal mb-1">Select Partner</Typography>
        <Select
          options={[{ _id: "all", name: "All" }, { _id: currentUser._id, name: "Self" }, ...userListArr]}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
          className="w-full "
          styles={polyfixSelect}
          name="userId"
          onChange={(selectedOption) => {
            handlePartnerChange(selectedOption);
          }}
          placeholder="Select Partner"
        />
      </span>

      {associateListArr.length > 0 && formProps.values.userId !== currentUser._id ? (
        <span>
          <Typography className="font-normal mb-1">Select Associate</Typography>
          <Select options={[{ _id: "all", name: "All" }, ...associateListArr]} getOptionLabel={(option) => option.name} getOptionValue={(option) => option._id} className="w-full " styles={polyfixSelect} name="childId" onChange={handleAssociateChange} placeholder="Select Associate" />
        </span>
      ) : (
        ""
      )}

      {advisorListArr.length > 0 && formProps.values.userId !== currentUser._id ? (
        <span>
          <Typography className="font-normal mb-1">Select Advisor</Typography>
          <Select options={[{ _id: "all", name: "All" }, ...advisorListArr]} getOptionLabel={(option) => option.name} getOptionValue={(option) => option._id} className="w-full " styles={polyfixSelect} name="grandChildId" onChange={(option: any) => formProps.setFieldValue("grandChildId", option._id)} placeholder="Select Advisor" />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default LeadReport;
